
  import { mapGetters } from 'vuex'

  export default {
    name: `DialogCheckLanguage`,
    data() {
      return {
        dialog: false,
        toggleCheck: undefined
      }
    },
    props: {},
    computed: {
      ...mapGetters({
        filters: `marketplace/filters`,
        selectedChecks: `marketplace/selectedChecks`,
      }),
      languages() {
        if (!!this.filters?.language) {
          const checksLanguageCodes = this.toggleCheck?.filter_options?.language ?? []
          return checksLanguageCodes.map(l => this.filters.language[l])
        }
        return []
      },
    },
    methods: {
      dialogHandler(check) {
        this.toggleCheck = check
        const alreadySelected = !!this.selectedChecks?.filter((i) => i.id === check.id).length
        const hasFilterOptionsLanguage = !!check?.filter_options?.language
        const filterLanguageExist = Object.keys(this.filters).includes(`language`)

        filterLanguageExist && hasFilterOptionsLanguage && !alreadySelected
          ? this.dialog=true
          : this.$emit(`select`, check)
      },
      applyLanguage(lang){
        this.toggleCheck.language = lang
        this.dialog = false
        this.$emit(`select`, this.toggleCheck)
      }
    }
  }
