
  import { mapGetters } from 'vuex'

  export default {
    name: `MenuChecksFilter`,
    data() {
      return {
        selectedFilters: {}
      }
    },
    computed: {
      ...mapGetters({
        filters: `marketplace/filters`,
        toggleSelectedFilters: `marketplace/selectedFilters`,
      }),
      vFilters() {
        const _filters = {}
        Object.entries(this.filters ?? {}).forEach(([key, items]) => {
          _filters[key] = Object.entries(items).map(([k, i]) => ({id: k, value: i}))
        })
        return _filters
      }
    },
    methods: {
      async select(key, value) {
        value.length ? this.selectedFilters[key] = value : delete this.selectedFilters[key]
        await this.$store.commit(`marketplace/selectedFilters`, this.selectedFilters)
        this.$emit(`on-change-filters`, this.selectedFilters)
      }
    }
  }
